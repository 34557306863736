/*
.content {
    min-height: 100%
}; */

.about-heading {
    position: absolute;
    /* top: 50px; */
    color: #ffffff;
}

.about-heading h1 {
    text-align: left;
    /* font: normal normal normal 32px/37px Raleway; */
    letter-spacing: 0px;
    color: #FFFFFF;
    font-weight: 600;
    opacity: 1;
}

.about-top {
    position: relative;
    text-align: center;
}

.about-head-txt {
    position: absolute;
    top: 8px;
    /* left: 16px; */
    text-align: right;
}

.about-p {
    text-align: left;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}

.about-content .col-12.col-sm-12.col-md-6.col-lg-6 {
    padding: 0px;
}


.get-luxy-widget {
    /* position: absolute;
    top: 8px; */ 
 background: url(../../assets/images/about-bg.png);
}
.get-luxy-widget h1{
    color:#fff;
    padding-top: 55px;
    padding-bottom: 55px;
    font-weight: 600;
}
.about-content {
    margin: 3rem;
    padding-bottom: 20px;
    min-height: 500px;
}

.about-car-inner {
    position: relative;
    color:#fff !important;
}
.about-car-inner-test{
    position: relative;
    }
.about-car-inner p{
    color:#fff !important;
}
.about-car-inner img {
    width: 100%;
    background: #000000;
}

.text-block {
    position: absolute;
    top: 70px;
    color: white;
    padding-left: 20px;
    padding-right: 20px;
}
.testimonial-block{
    /* color: #FFF; */
    color:black;
    padding:10px;
    /* background-image: url(../../assets/images/about-steering.png); */
}
.testimonial-item{
    position: relative;
    padding: 10px;
    align-items: center;
    color: #000;
}

.row {
    margin-right: 0px;
    margin-left: 0px;
}

@media (min-width: 992px) and (max-width: 1239px) {
    .text-block {
        top: 40px !important;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .text-block {
        top: 1px !important;
    }
}