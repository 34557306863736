.ride-container {
    background-image: url('../../assets/images/highway-airport.png');
    /* position: relative; */
    background-size: cover;
    /* height: 100%; */
    height: calc(100vh + 200px);
}

.ride-rewards-img {
    width: 100%;
    background: #000000;
}

.ride-6 {
    position: absolute;
}

.sign-up-ride-rewards{
    background: rgb(245,113,0, 0.5);
    border-radius: 10px;

    color: #fff;
}

.ride-wrapper {
    padding: 3rem;
}