.ex-link-inner {
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    height: 100vh;
}

img.img-redirect{
    height: 100px;
}

img.img-redirect{
    height: 90px;
}

.btn-orange-secondary {
    display: inline-block;
    line-height: 1.5;
    vertical-align: middle;
    color: #f07101;
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 1px;
    padding: 0.6rem 3rem;
    border: 1px solid #f07101;
    cursor: pointer;
    background: transparent;
    outline: none;
    font-size: 1em;
    white-space: nowrap;
}

.btn-orange-secondary:focus {
    outline: none;
}


.btn-orange-secondary:hover {
    background: #FFF7E0;
    box-shadow: 0 0 5px 1px #aeaeae;
}

.ex-link-inner p{
    font-size: 13px;
}

.ex-link-wrap {
    width: 100%;
    height: 100%;
}

.ex-link-wrap h2{
    font-size:1.75rem;
}

.ex-link-wrap h1{
    font-weight:bold;
}