.image-main-page-size{
    width: 421px;
    height: 209px;
}

.image-main-page-size1{
    width: 392px;
    height: 203px;
}

.image-main-page-size2{
    width: 401px;
    height: 205px;
}

.image-main-page-size3{
    width: 393px;
    height: 193px;
}

.image-main-page-size4{
    width: 384px;
    height: 197px;
}

.image-main-page-size5{
    width: 382px;
    height: 196px;
}

.airport-cards-container {
    display: flex;
    margin-right: auto;
    margin-left: auto;
    grid-column-gap: 20px;
    grid-row-gap: 20px;  
    width: 90%;
    flex-wrap: wrap;
    white-space: unset;
  }
  
  .airport-card {
    background: #000;
    opacity: 0.8;
    color: #fff;
    padding: 12px;
    width: 18%;
    word-wrap: break-word;
    border-radius: 10px;
    cursor: pointer;
    display: flex;
    text-align: center !important;
    justify-content: center;
    align-items: center;
    height: 85px;
  }

  .top-airport-h1{
    color: white;
    padding-bottom: 20px;
    width: 90%;
    margin-right: auto;
    margin-left: auto;  
    margin-top: 80px;
  }

@media (min-width: 424px) and (max-width: 768px) {
    .footer_img_BANNER {
        height: 100%;
    }
    .image-main-page-size {
        width: 384px;
        height: 197px;
    }
    .image-main-page-size1 {
        width: 384px;
        height: 197px;
    }
    .image-main-page-size2 {
        width: 384px;
        height: 197px;
    }
    .image-main-page-size3 {
        width: 384px;
        height: 197px;
    }
    .image-main-page-size4 {
        width: 384px;
        height: 197px;
    }
    .image-main-page-size5{
        width: 384px;
        height: 197px;
    }
    .airport-card {
        width: 100% !important;
        padding: 5px;
        height: 70px;
    }
    .airport-cards-container {
        grid-row-gap: 5px;
    }  
    .top-airport-h1{
        margin-top: 0px !important;
    }  
}

@media (min-width: 320px) and (max-width: 425px) {
    .footer_img_BANNER {
        height: 100%;
    }
    .image-main-page-size {
        width: 260px;
        height: 133px;
    }
    .image-main-page-size1 {
        width: 260px;
        height: 133px;
    }
    .image-main-page-size2 {
        width: 260px;
        height: 133px;
    }
    .image-main-page-size3 {
        width: 260px;
        height: 133px;
    }
    .image-main-page-size4 {
        width: 260px;
        height: 133px;
    }
    .image-main-page-size5 {
        width: 260px;
        height: 133px;
    }
    .airport-card {
        width: 100%;
        height: 65px;
    }
    .airport-card {
        width: 100% !important;
        padding: 5px;
    }
    .airport-cards-container {
        grid-row-gap: 5px;
    } 
    .top-airport-h1{
        margin-top: 0px !important;
    }  
}

@media (min-width: 769px) and (max-width: 991px) {
    .top-airport-h1{
        margin-top: 50px !important;
    }  
}