
.loginContainer {
    width: 560px;
    position: absolute;
    top: 8%;
    left: 50%;
    min-height: 500px;
    transform: translateX(-50%);
  }
  
  .logoHead {
    text-align: center;
  }
  .wrapper.loginWrapper {
    background: linear-gradient(to bottom, #f3f3f3, #dedede);
    background-size: cover;
    min-height: 100vh;
  }
  .loginBody {
    background: #fff;
    width: 442px;
    margin: 45px auto 0 auto;
    padding: 0 54px;
    border-radius: 0.45rem;
  }
  
  .loginBody h4 {
    text-align: center;
    font-size: 24px;
    text-transform: uppercase;
    color: #000;
    position: relative;
    font-family: 'Roboto-Bold', sans-serif;
    margin: 0;
    padding: 28px 0;
    font-weight: 600;
  }
  
  .card_custom .input-container {
    position: relative;
    width: 100%;
    float: right;
  }
  
  .submitBtn {
    padding: 0 0 45px 0;
  }
  .labelText {
    padding-left: 8%;
    padding-bottom: 8px;
  }
  .input-container i.fa-unlock-alt {
    font-size: 22px;
  }
  .input-container i {
    position: absolute;
    top: 40px;
    font-size: 18px;
  }
  .card_custom .input-container input {
    outline: none;
    z-index: 1;
    position: relative;
    background-color: transparent;
    width: 92%;
    height: 42px;
    border: 1px solid #212121;
    margin-top: 0;
    color: #212121;
    font-size: 20px;
    clear: both;
    font-weight: 400;
    float: right;
  }
  .loginBody .password-trigger {
    z-index: 1;
  }
  .custom-trigger {
    top: 35px;
    right: 10px;
  }
  .password-trigger {
    position: absolute;
    top: 22px;
    right: 45px;
    user-select: none;
  }
  .btn-outline-orange {
    display: inline-block;
    line-height: 1.5;
    vertical-align: middle;
    color: rgb(235, 128, 35);
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 1px;
    padding: 0.6rem 3rem;
    border: 1px solid rgb(235, 128, 35);
    cursor: pointer;
    background: transparent;
    background-image: none;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f9bc04', endColorstr='#f07101', GradientType=1);
    border-radius: 0.25rem;
    outline: none;
    font-size: 1em;
    white-space: nowrap;
  }
  
  .password-trigger i {
    top: 1.25rem;
    right: 0;
    left: 22px;
    color: #918f96;
    cursor: pointer;
  }
  
  
  .wrapper {
    background-color: transparent !important;
    position: relative;
  }