
.affiliate_img_BANNER{
    background-image: url(../../assets/images/affilaite-bg.png);
    background-size: cover;
    height: 400px;
    /* color: #fff !important; */
    text-align: center;
    background-position: center;
}

.card-header {
    background-color: white;
    border-bottom:none;
}
.plus-icon{
    color: #EB8122;
     vertical-align: 'baseline';
      font-size:'18px';
       font-weight:'bold'; 
}



.affiliate-registration-modal .modal-img {
   text-align: center;
}


.affiliateleadColumnContainer{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
}

.affiliate-registration-modal .form-control-file {
    height: 35px;
    display: block;
    width: 100%;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
}

.affiliate-registration-modal .form-file{
    display: inline-block;
}

.leftBorder {
    border-left: 1px solid #ccc;
}

@media (max-width: 767px) {
    .affiliateleadColumnContainer {
      grid-template-columns: 1fr;
    }
    .leftBorder {
        border-left: none;
    }
  }
@media (min-width: 768px) {
    .affiliate-registration-modal .modal-dialog {
        max-width: 1000px;
    }
  }

  .affiliate-registration-modal input[type="file"] {
    display: none;
  }
  
  .affiliate-registration-modal .custom-file-upload {
    display: inline-block;
    border: 2px dotted #ccc;
    border-radius: 4px;
    padding: 12px 25px;
    cursor: pointer;
    margin-right: 10px;
    text-align: center;
    margin-top:10px;
  }
  
  .affiliate-registration-modal .custom-file-upload::before {
    content: "+";
    display: inline-block;
    vertical-align: middle;
    font-size: 24px;
  }
  
  .affiliate-registration-modal .custom-file-upload + label {
    vertical-align: middle;
  }

  .affiliate-registration-modal .fileName{
    max-width: 100px;
    word-break: break-word;
}



