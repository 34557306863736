.wrapper {
    background-color: transparent !important;
}

.wrapper.loginWrapper {
    background: linear-gradient(to bottom, #f3f3f3, #dedede);
    background-size: cover;
    min-height: 100vh;
}


.loginBody .password-trigger {
    z-index: 1;
}
.changePassword .topText{
    font-family: "Monotype-HelveticaNowDisplayMedium" !important;
    font-weight: bold !important;
}
h4.topText {
    text-align: center;
    font-size: 24px;
    text-transform: uppercase;
    color: #000;
    position: relative;
    margin: 0;
    padding: 28px 0;
    font-weight: 600;
}
.password-trigger {
    position: absolute;
    top: 22px;
    right: 45px;
    user-select: none;
}

.loginBody .password-trigger {
    z-index: 1;
}

.password-trigger i {
    top: 5px;
    right: 0;
    color: #918f96;
    cursor: pointer;
}
.password .password-trigger {
    top: 48% !important;
}
.c-trigger {
    position: absolute;
    top: 34px;
    right: 20px;
}
.btn-outline-orange {
    display: inline-block;
    line-height: 1.5;
    vertical-align: middle;
    color: rgb(235, 128, 35);
    font-weight: 600;
    text-transform: uppercase;
    text-align: center;
    letter-spacing: 1px;
    padding: 0.6rem 3rem;
    border: 1px solid rgb(235, 128, 35);
    cursor: pointer;
    background:transparent;
    background-image:none;
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f9bc04', endColorstr='#f07101', GradientType=1);
    border-radius: 0.25rem;
    outline: none;
    font-size: 1em;
    white-space: nowrap;
}

.btn-outline-orange:focus {
    outline: none;
}

.btn-outline-orange:hover {
    box-shadow: 0 0 5px 1px #aeaeae;
}
a.btn-outline-orange{
    color: rgb(235, 128, 35);
    font-size: 0.45em;
}

a.btn-outline-orange:hover {
    text-decoration: none;
    color: rgb(235, 128, 35);
}
.loginBody {
    background: #fff;
    width: 442px;
    margin: 45px auto 0 auto;
    padding: 0 54px;
    border-radius: 0.45rem;
}

.loginBody h4 {
    text-align: center;
    font-size: 24px;
    text-transform: uppercase;
    color: #000;
    position: relative;
    font-family: 'Roboto-Bold', sans-serif;
    margin: 0;
    padding: 28px 0;
    font-weight: 600;
}

@media screen and (max-width:580px) {
    .loginBody {
        background: rgba(255, 255, 255, 0.72);
        width: 96%;
        margin: 55px auto 0 auto;
        padding: 0 15px;
    }

    .loginContainer {
        width: 100%;
        padding: 0 15px;
    }
}

.forGotWraper .error {
    margin-left: 45px;
    font-size: 13px;
    color: red;
    display: block;
    width: 100%;
    clear: both;
}

.rms-content-body .error,
.loginsignuppopup .error {
    font-size: 13px;
    color: red;
}
.error {    
    font-size: 13px;
    color: red;
}