.get-safety-widget {
  /* position: absolute;
    top: 8px; */
  background: url(../../assets/images/safety-bg.png);
}
.safety-top {
  position: relative;
  text-align: center;
}
.safety-heading {
  position: absolute;
  top: 200px;
  color: #ffffff;
}

.get-luxy-widget {
  background: url(../../assets/images/about-bg.png);
}

.image-safety {
  height: 500px;
  width: 100%;
  background-image: url(../../assets/images/safety-bg.png);
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  background-repeat: no-repeat;
}

.image-safety-rules {
  height: 260px;
  width: 100%;
}

.display-div {
  display: grid;
}

.safety-img-text-one {
  font-family: Helvetica;
  font-size: 25px;
  font-weight: bold;
  color: #ffffff;
}

.safety-img-text-two {
  font-family: Helvetica;
  font-size: 16px;
  font-weight: 400;
  color: #ffffff;
}

.grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 80px 0px 0px 80px;
  /* background-color: #2196F3; */
  /* padding-top: 40px;
    padding-left: 120px; */
  /* padding-right: 120px; */
}

.grid-item-one {
  /* background-color: rgba(255, 255, 255, 0.8);
    border: 1px solid rgba(0, 0, 0, 0.8); */
  /* padding: 20px; */
  /* font-size: 30px; */
  /* text-align: center; */
  /* display: grid; */
  /* padding-left: 40px !important; */
}

.grid-item-two {
  /* padding-left: 100px; */
  padding: 0px 130px 0px 100px;
}

.safety-vehicle-div {
  padding-top: 10px;
}

.our-door-div {
  text-align: center;
  padding-top: 10px;
}
.safety-text-div {
  justify-content: center;
  text-align: center;
  padding-top: 60px;
}

.image-description-padding {
  padding-top: 20px;
}

.image-description-one {
  font-family: Helvetica;
  font-size: 18px;
  font-weight: bold;
  color: #191919;
}

.safety-rules-div {
  padding: 90px 185px 0px 80px;
}

.image-description-two {
  font-family: Helvetica;
  font-size: 16px;
  font-weight: 400;
  color: #000000;
}

.display-image-description {
  display: flex;
}

.our-door-grid-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 50px 100px 0px 70px;
}

.our-door-grid-container-two {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 30px 100px 200px 70px;
}

.our-door-grid-item-one {
  display: flex;
  padding-right: 70px;
}

.our-door-grid-item-two {
  display: flex;
}

.expand {
  cursor: pointer;
}

.safety-rules-heading {
  font-family: Helvetica;
  font-size: 18px;
  font-weight: 400;
  color: #c34926;
}

.safety-rules-text {
  font-family: Helvetica;
  font-size: 16px;
  font-weight: 400;
  color: #191919;
  padding-left: 20px;
}
.color-black {
  color: #000000;
}
