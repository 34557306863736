@font-face {
    font-family: 'HelveticaNeue';
    src: local('HelveticaNeue Medium'), local('HelveticaNeueMedium'),
        url('HelveticaNeueMedium.woff2') format('woff2'),
        url('HelveticaNeueMedium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'HelveticaNeue';
    src: local('HelveticaNeue BlackCond'), local('HelveticaNeueBlackCond'),
        url('HelveticaNeueBlackCond.woff2') format('woff2'),
        url('HelveticaNeueBlackCond.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'HelveticaNeue';
    src: local('HelveticaNeue Light'), local('HelveticaNeueLight'),
        url('HelveticaNeueLight.woff2') format('woff2'),
        url('HelveticaNeueLight.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'HelveticaNeue';
    src: local('HelveticaNeue Bold'), local('HelveticaNeueBold'),
        url('HelveticaNeueBold.woff2') format('woff2'),
        url('HelveticaNeueBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'HelveticaNeue';
    src: local('HelveticaNeue Italic'), local('HelveticaNeueItalic'),
        url('HelveticaNeueItalic.woff2') format('woff2'),
        url('HelveticaNeueItalic.woff') format('woff');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'HelveticaNeue';
    src: local('HelveticaNeue Regular'), local('HelveticaNeueRegular'),
        url('HelveticaNeueRegular.woff2') format('woff2'),
        url('HelveticaNeueRegular.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'HelveticaNeue';
    src: local('HelveticaNeue Thin'), local('HelveticaNeueThin'),
        url('HelveticaNeueThin.woff2') format('woff2'),
        url('HelveticaNeueThin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'HelveticaNeue';
    src: local('HelveticaNeue Light'), local('HelveticaNeueLight'),
        url('HelveticaNeueLight.woff2') format('woff2'),
        url('HelveticaNeueLight.woff') format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'HelveticaNeue';
    src: local('HelveticaNeue Heavy'), local('HelveticaNeueHeavy'),
        url('HelveticaNeueHeavy.woff2') format('woff2'),
        url('HelveticaNeueHeavy.woff') format('woff');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'HelveticaNeue';
    src: local('HelveticaNeue Bold'), local('HelveticaNeueBold'),
        url('HelveticaNeueBold.woff2') format('woff2'),
        url('HelveticaNeueBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'HelveticaNeue';
    src: local('HelveticaNeue Medium'), local('HelveticaNeueMedium'),
        url('HelveticaNeueMedium.woff2') format('woff2'),
        url('HelveticaNeueMedium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

