.h1-success {
    color: #88B04B;
    font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
    font-weight: 900;
    font-size: 40px;
    margin-bottom: 10px;
    text-align: center;
}

.p-success {
    color: #404F5E;
    font-family: "Nunito Sans", "Helvetica Neue", sans-serif;
    font-size: 20px;
    margin: 0;
}

.payment-card {
   padding: 1.0rem 1.0rem !important;
}

.i-success {
    color: #9ABC66;
    font-size: 100px;
    line-height: 200px;
    margin-left: -15px;
}

.card {
    background: white;
    padding: 60px;
    border-radius: 4px;
    box-shadow: 0 2px 3px #C8D0D8;
    display: inline-block;
    margin: 0 auto;
}

.payment-success-pane {
    padding: 0rem !important;
    /* box-shadow: 1px 3px 6px #00000029; */
    background: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.success-content {
    top: 220px;
    left: 635px;
}

.success-img {
    width: 230px;
    height: 219px;
    background: transparent url(../../assets/images/success-img.svg) 0% 0% no-repeat padding-box;
    opacity: 1;
}

.success-text {
    text-align: center;
    font: normal normal normal 28px/33px Maven Pro;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
}

.payment-span-text {
    text-align: center;
    font: normal normal normal Maven Pro;
    letter-spacing: 0px;
    color: #C34926;
    opacity: 1;
}

.success-table {
    padding: 1rem 2rem !important;
    /* box-shadow: 1px 3px 6px #00000029; */
    /* background: #d0d0d0;  */
    background: #E2E2E2 0% 0% no-repeat padding-box;
    border-radius: 15px;
}

.dropoff {
    color: #00B200;
}