.row-1 {
    align-items: center !important;
}

.corporate-2 {
    margin: 5rem 10rem;
}

.corporate-2 h2 {
    font-weight: bold;
}

.cor-p2 {
    text-align: right !important;
}

.about-p2 {
    text-align: right !important;
}

/* .corporate-2 img {
    height: 20vh;
} */

.corporate-3 {
    background-color: #a9a9a929;
}

.corporate_img_BANNER{
    background-image: url(../../assets/images/corporate_1.png);
    background-size: cover;
    height: 400px;
    /* color: #fff !important; */
    text-align: center;
    background-position: center;
}

.corporate-3 img {
    height: 20vh;
}

.corporate-3 .row-1 {
    display: block;
    text-align: center;
    align-items: center;
    /* height: 30vh; */
    padding: 80px 400px;
}

.about-heading {
    position: absolute;
    color: #ffffff;
    align-items: center;
    text-align: center;
    /* padding: 200px; */
}

.about-heading p {
    color: #fff;
    padding: 0px 200px;
}

.corporate-3 h4 {
    font-size: 32px;
    font-weight: normal;
}

.corporate-3 button.btn.btn-submit {
    border-radius: 18px;
    color: #fff;
    width: 20% !important;
    margin-top: 20px;
}

.corporate-4 img {
    height: 50vh !important;
}

.corporate-4 .about-heading {
    /* top: 0px; */
}

.corporate-4 .corpo {
    /* top: auto !important; */
}

.corpo p {
    background: #000000;
    border-radius: 12px;
    opacity: 0.6;
    width: auto;
    margin: 30px 320px;
    height: auto;
    left: 100px;
    right: 100px;
    padding: 30px !important;
}

@media (min-width: 992px) and (max-width: 1239px) {
    .corporate-4 .about-heading p {
        width: auto !important;
        height: 20vh !important;
        margin: 0px !important;
    }
    .corporate-1 .about-heading p {
        padding: 0px !important;
    }
    .corporate-1 .about-heading {
        padding: 150px !important;
    }
    .corporate-3 .row {
        padding: 50px !important;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .about-heading p {
        padding: 0px !important;
    }
    .corporate-2 {
        margin: 2rem 5rem !important;
    }
    .corporate-3 .row-1 {
        padding: 30px !important;
    }
    .corporate-3 h4 {
        font-size: 24px !important;
    }
    .corporate-4 .corpo {
        padding: 100px !important;
    }
    .corporate-4 h1 {
        font-size: 20px !important;
    }
    .corpo p {
        width: 60% !important;
        margin: 30px 110px !important;
        height: 20vh !important;
        padding: 15px !important;
    }
}

@media (min-width: 576px) and (max-width: 767px) {
    .about-heading p {
        padding: 0px !important;
    }
    .corporate-2 {
        margin: 2rem 4rem !important;
    }
    .corporate-3 .row-1 {
        padding: 30px !important;
    }
    .corporate-3 h4 {
        font-size: 18px !important;
    }
    .corporate-4 .corpo {
        padding: 50px 100px !important;
    }
    .corporate-4 h1 {
        font-size: 20px !important;
    }
    .corpo p {
        width: auto !important;
        margin: 30px 110px !important;
        height: auto !important;
        padding: 15px !important;
    }
    .about-heading {
        padding: 100px !important;
    }
}