.get-luxy-container {
    background: #000000;
    border-radius: 12px;
    opacity: 0.8;
    width: 20%;
    margin: 10px;
    position: fixed;
    padding: 10px 20px;
    overflow: auto;
    z-index: 1000;
    left: 60px;
}



/* h2.text-left.font-white {
    font-weight: 700 !important;
    text-decoration: none !important;
    background-color: transparent !important;
    color: #fff !important;
    font-size: 22px !important;
    margin-bottom: 5px;
} */

.font-white {
    color: #ffffff;
}

/* button.btn.btn-submit {
    width: 30%;
    font-family: sans-serif;
    border-radius: 5px;
    font-weight: bold;
} */

.get-rates {
    background: #ff8d28;
    border-radius: 4px;
    opacity: 1;
    color: #fff
}

.txt-14 {
    font-size: 14px;
    text-align: left;
    margin: 0px;
}

@media (min-width: 1240px) and (max-width: 1347px) {
    .get-luxy-container {
        margin: 8px !important;
        padding: 10px 20px !important;
    }
    
    .txt-14 {
        font-size: 15px !important;
    }
    h2.text-left.font-white {
        font-size: 20px !important;
    }
}

@media (min-width: 992px) and (max-width: 1239px) {
    .get-luxy-container {
        left: 20px !important;
        padding: 5px 15px !important;
        margin: 8px 20px !important;
    }
    button.btn.btn-submit {
        border-radius: 18px;
        color: #fff;
        width: auto;
        margin-top: 20px;
        padding: 5px 15px;
        font-size: 20px;
    }
    .txt-14 {
        font-size: 12px !important;
    }
    h2.text-left.font-white {
        font-size: 16px !important;
        margin-bottom: 0px !important;
    }
}

@media (min-width: 768px) and (max-width: 991px) {
    .get-luxy-container {
        left: 20px !important;
        padding: 10px 15px !important;
        margin: 10px 0px !important;
    }
    button.btn.btn-submit {
        border-radius: 18px;
        color: #fff;
        width: auto;
        margin-top: 20px;
        padding: 5px 15px;
        font-size: 20px;
    }
    .txt-14 {
        font-size: 10px !important;
    }
    h2.text-left.font-white {
        font-size: 14px !important;
        margin-bottom: 2px !important;
    }
}

@media (min-width: 576px) and (max-width: 767px) {
    .get-luxy-container {
        left: 0px !important;
        padding: 5px 15px !important;
        margin: 4px 10px !important;
    }
    button.btn.btn-submit {
        border-radius: 18px;
        color: #fff;
        width: auto;
        margin-top: 20px;
        padding: 5px 15px;
        font-size: 20px;
    }
    .txt-14 {
        font-size: 8px !important;
    }
    h2.text-left.font-white {
        font-size: 13px !important;
        margin-bottom: 2px !important;
    }
}